@mixin white-gradient {
  background: linear-gradient(to right, rgba(255, 160, 57, 1) 0%, rgba(255, 160, 57, 1) 100%);
}
$animationSpeed: 40s;
$height: 40px;
// Animation
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-250px * 7))}
}
// Styling
.slider {
  background: rgba(255, 160, 57, 1);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
  height: $height;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  &::before,
  &::after {
    @include white-gradient;
    content: '';
    height: $height;
    position: absolute;
    //width: 50px;
    z-index: 2;
  }
  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  &::before {
    left: 0;
    top: 0;
  }
  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    //width: 100%;
    width: calc(250px * 14);
  }
  .slide {
    height: $height;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: rgba(78, 19, 76, 0.498039) -1px -1px 0px, rgba(78, 19, 76, 0.498039) -1px 1px 0px, rgba(78, 19, 76, 0.498039) 1px 1px 0px, rgba(78, 19, 76, 0.498039) 1px -1px 0px;
  }
}